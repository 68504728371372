@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Ledger&display=swap');

html,
body,
#root,
.app {
    height: 100%;
    width: 100%;
    font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #111111;
}

::-webkit-scrollbar-thumb {
    background: #181818;
}

::-webkit-scrollbar-track:hover {
    background: #181818;
}