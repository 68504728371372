.invisible-scroll {
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.invisible-scroll::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.invisible-scroll {
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
    scrollbar-width: none;
    /* For Firefox */
}